import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Hero from "../components/Hero";
import BookNow from "../components/BookNow";
import Contact from "../components/Contact";
import ContactBar from "../components/ContactBar";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
import Medium from "../components/packages/Medium";

const IndexPage = (props) => {
  const heroImages = [
    {
      sizes: props.data.schmerzexpertin.fluid,
      title: "Paola Nakahara",
      meta: "Expertin für Gynäkologie und Geburtshilfe",
      path: "ueber-mich/",
      isMain: true,
    },
    // {
    //   sizes: props.data.pakete.fluid,
    //   title: "Bis zu 20% sparen!",
    //   meta: "Buchen Sie jetzt!",
    //   path: "kosten/",
    // },
    {
      sizes: props.data.akupunktur.fluid,
      title: "Akupunktur",
      meta: "Organe stärken und Schmerzen beseitigen...",
      path: "angebot/akupunktur/",
    },
    {
      sizes: props.data.herbs.fluid,
      title: "Kräutermedizin",
      meta: "4000 Jahre Erfahrung...",
      path: "angebot/kraeutermedizin/",
    },
    {
      sizes: props.data.moxa.fluid,
      title: "Moxa",
      meta: "Punktuelle Wärmebehandlung...",
      path: "angebot/moxa/",
    },
    {
      sizes: props.data.schroepfen.fluid,
      title: "Schröpfen",
      meta: "Muskelverspannungen lösen... krankmachende Faktoren ausleiten...",
      path: "angebot/schroepfen/",
    },
  ];

  return (
    <Layout location={props.location}>
      <div id="index" className="page">
        <Helmet>
          <title>TCM Praxis Paola Nakahara-Hoffmann</title>
          <meta
            name="description"
            content="Ihre Praxis für Traditionelle Chinesische Medizin (TCM) in Zürich Oerlikon"
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="TCM Praxis Paola Nakahara-Hoffmann"
          />
          <meta
            property="og:description"
            content="Die Traditionell Chinesische Medizin (TCM) befasst sich mit der Ganzheitlichkeit und dem Gleichgewicht des Körpers und blickt auf eine über 1000-jährige Erfahrung zurück."
          />
          {/* <meta name="keywords" content="KEYWORDs§" /> */}
        </Helmet>
        <ContactBar className="large-screen" />
        <Hero images={heroImages} />
        <div className="container">
          {/* <section className="alert">
            <p>Wegen Ferien und Umzug bleibt meine Praxis vom 4.8.2020 bis 24.8.2020 geschlossen.</p>
            <p>Ich freue mich sehr darauf Sie in den neuen Räumlichkeiten an der Baumackerstrasse 49, 8050 Zürich begrüssen zu dürfen.</p>
          </section> */}
          <section>
            <h3 lang="de">
              Beschwerden rund um die Menstruation und in den Wechseljahren
            </h3>
            <div>
              <p>
                Der Hormonhaushalt der Frau hat Auswirkung auf viele Bereiche
                des täglichen Lebens. Menstruationsbeschwerden, unregelmässige
                Menstruation, PMS, Hitzewallungen, Schlafstörungen sind nur ein
                kleiner Teil der Symptome. Ein individueller Therapieplan aus{" "}
                <Link to="/angebot/akupunktur/">Akupunktur</Link> und{" "}
                <Link to="/angebot/kraeutermedizin/">
                  chinesischen Kräutern
                </Link>{" "}
                bringt Sie und Ihre Hormone wieder ins Gleichgewicht.
              </p>
              <blockquote>
                Ich habe mich bei Paola von Beginn weg wohl gefühlt. Die
                Gespräche waren von Anfang an vollem Vertrauen und sehr
                sympathisch. Da ich grosse Schmerzen bei der Mens hatte, haben
                wir eine Akupunkturbehandlung gemacht und die hat voll
                eingeschlagen. Ich habe kaum mehr Beschwerden, einfach toll,
                danke!
                <footer>
                  <cite className="author">— Vivi v.A.</cite>
                </footer>
              </blockquote>
            </div>
          </section>

          <section>
            <h3 lang="de">Vom Kinderwunsch bis zur Geburt</h3>
            <div>
              <p>
                Versuchen Sie schon länger schwanger zu werden, aber leider ohne
                Erfolg? Sind Sie schwanger, aber über die Freude dominieren
                Beschwerden wie Übelkeit, Sodbrennen, Müdigkeit oder
                Rückenschmerzen? Lesen Sie meinen Blog zum Thema{" "}
                <Link to="/blog/schwangerschaft-im-wandel-der-5-elemente">
                  Schwangerschaft Im Wandel der 5 Elemente
                </Link>
                . Holen Sie sich kostenlose Tipps für ein besseres Wohlbefinden,
                oder <Link to="/kontakt">machen Sie einen Termin ab</Link> zur
                Akupunktur.
              </p>
              <blockquote>
                Paola hat mich vor und während meiner gesamten Schwangerschaft
                begleitet. Ich kam aufgrund meines unregelmässigen Zyklus und{" "}
                <Link to="/blog/tcm-bei-kinderwunsch">Kinderwunsch</Link> zu
                ihr. Im ersten Übungszyklus nach Start der Akupunktur wurde ich
                schwanger. Ein Volltreffer! Paola hat mich sehr kompetent und
                individuell je nach Befindlichkeit und Schwangerschaftswoche
                behandelt. Kann sie wärmstens empfehlen.
                <footer>
                  <cite className="author">— Debora G.</cite>
                </footer>
              </blockquote>
            </div>
          </section>

          {/* <section>
            <h3 lang="de">Sanfte Akupunktur zur Schmerzbehandlung</h3>
            <div>
              <p>Leiden Sie schon seit längerem an Migräne, Arthrose oder Rückenschmerzen? Haben Sie sich erst kürzlich verletzt oder überarbeitet? Mit Akupunktur, Schröpfen, Massage, oder Kinesiotape, stelle ich für Sie den individuellen Therapieplan zusammen.</p>
              <blockquote>
                Paola hat mir schon bei manchen Schmerzen geholfen. Sie ist sehr professionell, kompetent und freundlich. Sie hat auch gute schulmedizinische Kenntnisse und kann so auch eine bestehende Diagnose mit TCM verbinden und behandeln. Zudem hat sie eine sehr gute Nadeltechnik die sanft ist und nicht weh tut.
                <footer>
                  <cite className="author">— Edna S.</cite>
                </footer>
              </blockquote>
            </div>
          </section> */}

          <section>
            <h3 lang="de">Kann eine Wurzel Ihr Wohlbefinden steigern?</h3>
            <div>
              <p>
                Leiden Sie schon seit längerer Zeit unter Schlaf oder
                Verdauungsstörungen? Fühlen Sie sich gestresst, müde und
                ausgelaugt?
              </p>
              <p>
                Die{" "}
                <Link to="angebot/kraeutermedizin/">
                  Chinesische Kräutermedizin
                </Link>{" "}
                bringt Ihren Körper mit den regulierenden und aufbauenden
                Substanzen wieder ins Gleichgewicht.
              </p>
              <blockquote>
                Ich war bereits mehrere Male bei Paola in Behandlung. Nach einer
                Knieverletzung und Anschliessender Operation hat die Akupunktur
                extrem gut geholfen gegen die Schmerzen. Auch die Behandlung für
                meine Allergien mit einer Kräuter-Mischung zeigte gerade in der
                Heuschnupfen Saison eine enorme Wirkung. Ihr breites Wissen an
                klassischer Schulmedizin sowie im TCM schätze ich sehr und so
                kann ich mich gut auf Ihre Behandlungen einlassen. Ich würde sie
                jederzeit weiterempfehlen.
                <footer>
                  <cite className="author">— Yoshi N.</cite>
                </footer>
              </blockquote>
            </div>
          </section>

          {/*<section>
            <h3 lang="de">Buchen Sie eines meiner Vorteilsangebote</h3>
            <div>
              <p> Zum Beispiel:</p>
              <Medium small />
              <div>Weitere attraktive Angebote <Link to="kosten/">finden Sie hier</Link>.</div>
            </div>
          </section>*/}
        </div>
        <BookNow />
        <Contact />
        <Footer />
      </div>
    </Layout>
  );
};

export default IndexPage;

// pakete: imageSharp(fluid: { originalName: {regex: "/vorteils_pakete/" }}) {
//   fluid(maxHeight: 580, cropFocus: ENTROPY ) {
//     ...GatsbyImageSharpFluid
//   }
// },

export const query = graphql`
  query HeroImagesQuery {
    schmerzexpertin: imageSharp(
      fluid: { originalName: { regex: "/utensilien/" } }
    ) {
      fluid(maxHeight: 580, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
    akupunktur: imageSharp(fluid: { originalName: { regex: "/akupunktur/" } }) {
      fluid(maxHeight: 580, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
    herbs: imageSharp(fluid: { originalName: { regex: "/kraeuter/" } }) {
      fluid(maxHeight: 580, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
    moxa: imageSharp(fluid: { originalName: { regex: "/moxa/" } }) {
      fluid(maxHeight: 580, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
    schroepfen: imageSharp(fluid: { originalName: { regex: "/schroepfen/" } }) {
      fluid(maxHeight: 580, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
