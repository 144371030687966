import React, { Component } from 'react';
import { Link } from 'gatsby';
import Carousel from 'nuka-carousel';
import Img from 'gatsby-image';
import SliderControlLeft from '../library/slider/SliderControlLeft';
import SliderControlRight from '../library/slider/SliderControlRight';

class Hero extends Component {

  componentDidMount() {
    setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
    }, 0);
  }

  render () {
    
    const { images } = this.props;
    
    return (
      <section id="hero">
        <Carousel
          renderCenterLeftControls={SliderControlLeft}
          renderCenterRightControls={SliderControlRight}
          wrapAround
          autoplayInterval={7000}
          afterSlide={this.afterSlideChange}
          transitionMode="fade"
          dragging
          autoplay>
          
          {images
            .map((image) => {
              const children = [image.title, <Link to={image.path}><div className="meta">{image.meta}</div></Link>]
              return (
                <div className="slide" key={image.path}>
                  <Img sizes={image.sizes} />
                  <div className="slide-text">
                    {React.createElement(image.isMain ? 'h1' : 'h2', {className: 'title'}, children)}
                  </div>
                </div>
              );
            })}
          
        </Carousel>

      </section>
    );
  }
}

export default Hero;
